<template>
  <div class="list-info">
    <top-bar title="巡查列表" :left="true"></top-bar>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
      <p>新增、添加</p>
    </div>
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
          <div class="title">
            <div class="title-left">
              {{item.levelStr + ' | ' + item.communityStr}}
            </div>
            <div class="title-right">
              {{item.typeStr}}
            </div>
          </div>
          <div class="content">
            <div class="content-text">
              <div class="top-text">上访事由: {{item.reason}}</div>
              <div class="bottom-text">是否涉军: {{item.army==1?'是':'否'}}</div>
            </div>
            <van-icon name="arrow" color="#666" class="content-icon"/>
          </div>
        </div>
      </van-list>
      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
export default {
  props: ['userId', 'userName'],
  components :{
    topBar
  },
  data() {
    return {
      loading: false,
      finished: false,
      page: 0,
      limit: 10,
      totalCount: 0,
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getInfo (id) {
      console.log( this.userId)
      this.$router.push({path: '/petition-info', query: {id: id, userId: this.userId, userName: this.userName}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/petition/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          userId: this.userId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          // let dataList = data.page.list
          // dataList.map(item => {
          //   if (item.inspectionTime) {
          //     item.inspectionTime = item.inspectionTime.split(' ')[0]
          //   }
          // })
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    goAdd () {
      this.$router.push({path: '/petition-info', query: {userId: this.userId, userName: this.userName}})
    }
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
  }
}
</script>
<style scoped lang="scss">
.content-text {
  margin-left: 0 !important;
}
</style>
